import React, { FC, useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { ScrollView } from "@templafy/ui/components/scrollView/ScrollView";
import { Stack } from "@templafy/ui/components/stack/Stack";
import { FlexBox } from "@templafy/ui/components/flexBox/FlexBox";
import { Breadcrumbs } from "@templafy/ui/components/breadcrumbs/Breadcrumbs";
import { Button } from "@templafy/ui/components/button/Button";
import { IconLeft } from "@templafy/ui/icons/IconLeft";
import { Grid } from "@templafy/ui/components/grid/Grid";
import { Text } from "@templafy/ui/components/text/Text";
import { EmptyFolderIllustration } from "@templafy/ui/illustrations/EmptyFolderIllustration";
import { Asset, Folder, GenerateDocumentFormValues, LibraryType } from "types";
import {
  getLibraryAssets,
  getLibraryFolder,
  getLibraryFolders,
} from "api/utilities";
import { AssetRow, LibraryFolderRow } from "./AssetRow";
import { SpaceRow } from "./SpaceRow";

export const LibraryTree: FC = () => {
  const { values, setFieldValue } =
    useFormikContext<GenerateDocumentFormValues>();
  const { tenantName, apiKey, libraries, library } = values;

  const [rootFolderId, setRootFolderId] = useState<string>(null);

  const [folders, setFolders] = useState<Folder[]>([]);
  const [folder, setFolder] = useState<Folder>(null);
  const [assets, setAssets] = useState<Asset[]>([]);

  const availableLibraries = libraries.filter(({ libraryType }) =>
    [LibraryType.documents, LibraryType.presentations].includes(libraryType)
  );

  // Navigation bar
  const [selectedFolders, setSelectedFolders] = useState<Folder[]>([]);

  console.log({ availableLibraries, rootFolderId, assets, selectedFolders });

  useEffect(() => {
    if (!library) {
      return;
    }

    getLibraryFolders({
      tenantName,
      apiKey,
      spaceId: library.spaceId,
      libraryType: library.libraryType,
      folderId: folder ? folder.id : rootFolderId,
    }).then((data) => {
      setFolders(data);
    });
  }, [tenantName, apiKey, rootFolderId, library, folder]);

  useEffect(() => {
    if (!rootFolderId || !library) {
      return;
    }

    getLibraryFolder({
      tenantName,
      apiKey,
      spaceId: library.spaceId,
      libraryType: library.libraryType,
      folderId: rootFolderId,
    }).then((folder) => {
      // setRootFolder(folder);
      handleFolderSelect(folder);
    });
  }, [tenantName, apiKey, library, rootFolderId]);

  useEffect(() => {
    if (!library) {
      return;
    }

    getLibraryAssets({
      tenantName,
      apiKey,
      spaceId: library.spaceId,
      libraryType: library.libraryType,
      folderId: folder ? folder.id : rootFolderId,
    }).then((assets) => {
      setAssets(assets);
    });
  }, [tenantName, apiKey, rootFolderId, library, folder]);

  const hasSamePath = (existingFolder: Folder, incomingFolder: Folder) =>
    existingFolder.navigationPath === incomingFolder.navigationPath;

  const handleFolderSelect = (folder: Folder) => {
    if (selectedFolders.length === 0) {
      setSelectedFolders([folder]);
      return;
    }

    const { navigationPath: latestFolderPath } = selectedFolders.at(-1);

    if (latestFolderPath === folder.navigationPath) {
      return;
    }

    if (folder.navigationPath.includes(latestFolderPath)) {
      // Set the folder
      setSelectedFolders((prevState) => [...prevState, folder]);
    } else {
      const index = selectedFolders.findIndex((item) =>
        folder.navigationPath.includes(item.navigationPath)
      );
      // Remove the folder
      if (index > -1) {
        const existingFolder = selectedFolders[index];
        if (hasSamePath(existingFolder, folder)) {
          setSelectedFolders([...selectedFolders.slice(0, index + 1)]);
        } else {
          setSelectedFolders([...selectedFolders.slice(0, index + 1), folder]);
        }
      } else {
        setSelectedFolders([folder]);
      }
    }
  };

  return (
    <Stack>
      {rootFolderId && (
        <FlexBox>
          <Button
            variant="ghost"
            onClick={() => {
              // Reset all
              setRootFolderId(null);
              setFieldValue("library", null);
              setFolders([]);
              setSelectedFolders([]);
              setAssets([]);
              setFolder(null);
            }}
            startIcon={IconLeft}
          >
            Back to spaces
          </Button>
        </FlexBox>
      )}
      <FlexBox>
        <Breadcrumbs
          items={selectedFolders.map((folder) => ({
            value: folder.id,
            label: folder.name,
            testId: folder.name,
          }))}
          onItemClick={(folderId) => {
            const folder = selectedFolders.find(
              (folder) => folder.id === folderId
            );
            setFolder(folder);
            handleFolderSelect(folder);
          }}
        />
      </FlexBox>
      <ScrollView height={300}>
        <Stack gap={0}>
          {rootFolderId ? (
            <>
              {folders.map((folder) => (
                <LibraryFolderRow
                  key={folder.id}
                  {...folder}
                  onClick={() => {
                    setFolder(folder);
                    handleFolderSelect(folder);
                  }}
                />
              ))}
              {assets.map((asset) => (
                <AssetRow
                  key={asset.id}
                  {...asset}
                  libraryType={library.libraryType}
                  onClick={() => setFieldValue("assetId", asset.id)}
                  isSelected={values.assetId === asset.id}
                />
              ))}
              {folders.length === 0 && assets.length === 0 && (
                <FlexBox
                  gap={300}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <EmptyFolderIllustration width={200} height={200} />
                  <Text size={300}>This folder is empty</Text>
                </FlexBox>
              )}
            </>
          ) : (
            <Grid gap={300} itemMinWidth={800}>
              {availableLibraries.map((library) => (
                <SpaceRow
                  key={library.id}
                  {...library}
                  onClick={() => {
                    setRootFolderId(library.rootFolderId);
                    setFieldValue("library", library);
                  }}
                />
              ))}
            </Grid>
          )}
        </Stack>
      </ScrollView>
    </Stack>
  );
};
