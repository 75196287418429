import React, { FunctionComponent } from "react";
import { Alert } from "@templafy/ui/components/alert/Alert";
import { Cluster } from "@templafy/ui/components/cluster/Cluster";
import { IconInfo } from "@templafy/ui/icons/IconInfo";
import { Text } from "@templafy/ui/components/text/Text";
import { Link } from "@templafy/ui/components/link/Link";
import { Button } from "@templafy/ui/components/button/Button";

import { openDocumentInOffice } from "helpers";
import { GeneratedFile } from "types";

type FormSubmissionResultProps = { generatedDocument?: GeneratedFile };
export const FormSubmissionResult: FunctionComponent<
  FormSubmissionResultProps
> = ({ generatedDocument }) => {
  if (!generatedDocument) {
    return null;
  }
  
  return (
    <Alert gap={400} status="success" style={{ marginBottom: 40 }}>
      <Cluster gap={200}>
        <IconInfo />
        <Text>
          Document has been generated. &nbsp;
          <Link external href={generatedDocument.downloadUrl}>
            Download
          </Link>
        </Text>
      </Cluster>
      <Button
        variant={"ghost"}
              onClick={() => openDocumentInOffice(generatedDocument.downloadUrl, generatedDocument.fileExtension)}
        size={"small"}
      >
        Open in Office
      </Button>
    </Alert>
  );
};
