import React, { FunctionComponent, useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { Form } from "@templafy/ui/components/form/Form";
import { TextInput } from "@templafy/ui/components/textInput/TextInput";
import { Text } from "@templafy/ui/components/text/Text";
import { Center } from "@templafy/ui/components/center/Center";
import { Select } from "@templafy/ui/components/select/Select";
import { Button } from "@templafy/ui/components/button/Button";

import { getAssetById, getLibraries } from "api/utilities";
import { getBaseStateFromLocalStorage } from "helpers";
import { GeneratedFile, GenerateDocumentFormValues, Library } from "types";
import { useDebounce } from "hooks/useDebounce";

import { FormSubmissionResult } from "./FormSubmissionResult";
import { UnattendedFailed } from "./UnattentedFailed";
import { LibraryTree } from "./libraryTree/LibraryTree";

const stepOneDescription = `Make sure to get the API token from your Templafy Tenant and fill it out together with tenant name and the user email.`;

const stepTwoDescription = `Select Space
                            on the left and, Type an Asset
                            from the Space`;

const ENABLED_LIBRARY_TYPE = "documents";
const getLibrariesOptions = (libraries: Library[]) =>
  libraries.map((library) => ({
    label: `${library.name} - space (${library.spaceId})`,
    value: library,
    disabled: library.libraryType !== ENABLED_LIBRARY_TYPE,
  }));

export type MaybeGeneratedFile = GeneratedFile | undefined;
export const GenerateDocument: FunctionComponent = () => {
  // Form
  const { values, errors, submitForm, setFieldValue, setFieldError } =
    useFormikContext<GenerateDocumentFormValues>();

  const { tenantName, apiKey, email, library, assetId } = values;

  // Collapsible state
  const [stepOneCollapsed, setStepOneCollapsed] = useState(false);
  const [stepTwoCollapsed, setStepTwoCollapsed] = useState(true);

  // Debounce values
  const debouncedTenantName = useDebounce(tenantName);
  const debouncedApiKey = useDebounce(apiKey);
  const debouncedAssetId = useDebounce(assetId);

  // Derived state
  const disabledStepTwo = !tenantName || !apiKey || !email;

  // Final step
  const [generatedDocument, setGeneratedDocument] = useState(null);

  // Mount
  useEffect(() => {
    const state = getBaseStateFromLocalStorage();
    if (state) {
      setFieldValue("tenantName", state.tenantName);
      setFieldValue("apiKey", state.apiKey);
      setFieldValue("email", state.email);
    }
  }, [setFieldValue]);

  // Libraries
  useEffect(() => {
    if (!debouncedTenantName || !debouncedApiKey) {
      return;
    }

    getLibraries({
      tenantName: debouncedTenantName,
      apiKey: debouncedApiKey,
    }).then((libraries) => {
      if (!libraries) {
        return;
      }

      setFieldValue("libraries", libraries);
    });
  }, [debouncedTenantName, debouncedApiKey, setFieldValue]);

  // Get Asset
  useEffect(() => {
    if (!library || !debouncedAssetId) {
      // Reset the saved asset
      setFieldValue("asset", null);
      return;
    }

    getAssetById({
      tenantName,
      apiKey,
      spaceId: library.spaceId,
      libraryType: library.libraryType,
      assetId: debouncedAssetId,
    })
      .then((asset) => {
        setFieldValue("asset", asset);
        setFieldError("assetId", undefined);
      })
      .catch((e) => {
        setFieldError("assetId", e.code === 404 ? "Not found" : e.message);
        // Reset the saved asset
        setFieldValue("asset", null);
      });
  }, [
    library,
    debouncedAssetId,
    tenantName,
    apiKey,
    setFieldValue,
    setFieldError,
  ]);

  // @ts-ignore
  return (
    <Center maxWidth={1200}>
      <Form
        id={"document-generate"}
        size={"large"}
        style={{ paddingTop: 50 }}
        onSubmit={async (event) => {
          event.preventDefault();
          try {
            const response =
              (await submitForm()) as unknown as MaybeGeneratedFile;

            if (response) {
              setGeneratedDocument(response);
            }
          } catch (e) {
            // Reset generated document
            setGeneratedDocument(null);
          }
        }}
      >
        <Form.FieldSet
          title={"Account information"}
          description={stepOneDescription}
          collapsed={stepOneCollapsed}
          onCollapseChange={setStepOneCollapsed}
        >
          <Form.Field>
            <Form.Label>Tenant Name</Form.Label>
            <TextInput
              required
              placeholder={"Tenant name"}
              value={tenantName}
              onChange={(value) => setFieldValue("tenantName", value)}
              status={errors.tenantName ? "error" : undefined}
            />
            <Form.ValidationMessage>{errors.tenantName}</Form.ValidationMessage>
            <Form.HelpMessage>The name of the Templafy Tenant</Form.HelpMessage>
          </Form.Field>
          <Form.Field>
            <Form.Label
              tooltip={'Make sure "library-generate" scope is enabled for it.'}
            >
              API Key
            </Form.Label>
            <TextInput
              required
              value={apiKey}
              onChange={(value) => setFieldValue("apiKey", value)}
              status={errors.apiKey ? "error" : undefined}
            />
            <Form.ValidationMessage>{errors.apiKey}</Form.ValidationMessage>
            <Form.HelpMessage>
              The API key copied from Templafy admin center.
            </Form.HelpMessage>
          </Form.Field>
          <Form.Field>
            <Form.Label>Email</Form.Label>
            <TextInput
              required
              placeholder={"Email of a valid user for the tenant"}
              value={email}
              onChange={(value) => setFieldValue("email", value)}
              status={errors.email ? "error" : undefined}
            />
            <Form.ValidationMessage>{errors.email}</Form.ValidationMessage>
            <Form.HelpMessage>
              Templafy user email address that will be used to populate user
              profile details
            </Form.HelpMessage>
          </Form.Field>
        </Form.FieldSet>
        <Form.FieldSet
          title="Asset information"
          description="Select an asset from the Library"
          collapsed={stepTwoCollapsed}
          onCollapseChange={setStepTwoCollapsed}
        >
          <LibraryTree />
        </Form.FieldSet>
        <Form.FieldSet
          title={"Final Step"}
          description={`Create and open Document`}
        >
          <Text bold>
            Request will be send to:{" "}
            <Text
              size={100}
              style={{
                width: "100%",
                overflowWrap: "break-word",
                fontFamily: "monospace",
              }}
            >
              {values.apiUrl}
            </Text>
          </Text>

          <Button type={"submit"} action={"primary"} aria-label={"Execute"}>
            Execute
          </Button>
        </Form.FieldSet>
      </Form>
      {generatedDocument ? (
        <FormSubmissionResult generatedDocument={generatedDocument} />
      ) : (
        <UnattendedFailed
          url={values.popupUrl}
          data={values.data}
          setGeneratedDocument={setGeneratedDocument}
        />
      )}
    </Center>
  );
};
