import { FormikErrors, FormikHelpers } from "formik";
import { NotificationService } from "@templafy/notifications";
import { saveBaseStateToLocalStorage } from "../helpers";
import {
  ApiError,
  endpoints,
  generateDocument,
  getBaseUrl,
} from "../api/utilities";
import { GeneratedFile, GenerateDocumentFormValues } from "../types";

const defaultData = {
    "Subject": "Order details",
    "orderDate": "03-12-2021",
    "shippedDate": "08-01-2022",
    "freight": "65.8300",
    "orderValueWithoutVAT": "209,6",
    "VAT": "44,0",
    "orderValue": "253,6",
    "reference": "42394672384",
    "customer": {
        "customerID": "VINET",
        "customerName": "Ana Trujillo Emparedados y helados",
        "contactName": "Ana Trujillo",
        "address": "Avda. de la Constitución 2222",
        "city": "México D.F.",
        "title": "CEO",
        "postalCode": "05021",
        "country": "Mexico",
        "phone": "(5) 555-4729"
    },
    "orderLines": [
        {
            "productName": "Bananas",
            "productImage": "https://images.immediate.co.uk/production/volatile/sites/30/2017/01/Bananas-218094b-scaled.jpg",
            "productId": "10248",
            "unitPrice": "9.8",
            "quantity": 10,
            "total": "98"
        },
        {
            "productName": "Apples",
            "productImage": "https://i5.walmartimages.com/asr/f46d4fa7-6108-4450-a610-cc95a1ca28c5_3.38c2c5b2f003a0aafa618f3b4dc3cbbd.jpeg",
            "productId": "10249",
            "unitPrice": "18.6",
            "quantity": 6,
            "total": "111,6"
        }
    ]
};

const requiredFieldValidationMessage = "Field is required";
export const useGenerateDocumentForm = () => {
  const initialValues: GenerateDocumentFormValues = {
    // Step 1
    tenantName: "",
    apiKey: "",
    email: "",
    // Step 2
    libraries: [],
    library: null,
    assetId: "",
    asset: null,
    // Step 3
    data: defaultData,
    // Extra
    apiUrl: "",
    popupUrl: "",
  };

  const validate = (values: GenerateDocumentFormValues) => {
    const { tenantName, apiKey, email, library, assetId } = values;

    const errors: FormikErrors<GenerateDocumentFormValues> = {};

    if (!tenantName) {
      errors.tenantName = requiredFieldValidationMessage;
    }
    if (!apiKey) {
      errors.apiKey = requiredFieldValidationMessage;
    }
    if (!email) {
      errors.email = requiredFieldValidationMessage;
    }

    if (!library) {
      // @ts-ignore
      errors.library = requiredFieldValidationMessage;
    }
    if (!assetId) {
      errors.assetId = requiredFieldValidationMessage;
    }

    return errors;
  };

  const onSubmit = async (
    values: GenerateDocumentFormValues,
    formik: FormikHelpers<GenerateDocumentFormValues>
  ) => {
    const { tenantName, apiKey, email, library, assetId, data } = values;

    // Persist base state
    saveBaseStateToLocalStorage({ tenantName, email, apiKey });

    const popupUrl = `https://${tenantName}.templafy.com/library/id/${assetId}?externalSystemType=genericAppConnector&origin=${window.location.origin}`;
    try {
      // Make the API call
      const spaceId = library.spaceId;
      const libraryType = library.libraryType;

      const response: GeneratedFile = await generateDocument({
        tenantName,
        apiKey,
        assetId,
        spaceId,
        libraryType,
        email,
        data,
      });

      formik.setFieldValue(
        "apiUrl",
        `${getBaseUrl(tenantName)}/${
          endpoints.Libraries
        }/${spaceId}/${libraryType}/assets/${assetId}/generate`
      );

      return response;
    } catch (error) {
      if (!(error instanceof ApiError)) {
        return;
      }

      if (error.code === 404) {
        NotificationService.error({
          title: "Asset not found for document generation",
          description: error.message,
        });
      }

      if (error.code === 500 || error.code === 400) {
        formik.setFieldValue("popupUrl", popupUrl);
        throw error;
      }
    }
  };

  return {
    initialValues,
    validate,
    onSubmit,
  };
};
