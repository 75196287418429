import React, { FC } from "react";
import { Cluster } from "@templafy/ui/components/cluster/Cluster";
import { Text } from "@templafy/ui/components/text/Text";
import { FlexBox } from "@templafy/ui/components/flexBox/FlexBox";
import { colorPaletteValues } from "@templafy/ui/designTokens/generated/DesignTokens";

import { Asset, Folder, LibraryType } from "types";
import { IconFolderLarge } from "@templafy/ui/icons/IconFolderLarge";
import { IconFileLarge } from "@templafy/ui/icons/IconFileLarge";
import { IconPresentationLarge } from "@templafy/ui/icons/IconPresentationLarge";
import { IconDocumentLarge } from "@templafy/ui/icons/IconDocumentLarge";
import { IconCheckmark } from "@templafy/ui/icons/IconCheckmark";
import { Theme } from "@templafy/ui/theme/theme";

export type AssetIconProps = {
  libraryType: LibraryType;
};
const AssetIcon: FC<AssetIconProps> = ({ libraryType }) => {
  switch (libraryType) {
    case LibraryType.documents: {
      return <IconDocumentLarge />;
    }
    case LibraryType.presentations: {
      return <IconPresentationLarge />;
    }
  }
  return <IconFileLarge />;
};

export const AssetRow: FC<
  Pick<Asset, "name" | "description"> & {
    onClick: () => void;
    libraryType: LibraryType;
    isSelected: boolean;
  }
> = ({ name, description, onClick, libraryType, isSelected }) => {
  return (
    <FlexBox
      gap={300}
      onClick={onClick}
      style={{
        cursor: "pointer",
        backgroundColor: isSelected
          ? colorPaletteValues["color-success-100"]
          : colorPaletteValues["color-white"],
        padding: 16,
        borderBottom: `1px solid ${colorPaletteValues["color-gray-500"]}`,
      }}
    >
      <AssetIcon libraryType={libraryType} />
      <Text ellipsis>{name}</Text>
      {isSelected && (
        <Cluster>
          <IconCheckmark theme={Theme.Success} />
        </Cluster>
      )}
    </FlexBox>
  );
};

export const LibraryFolderRow: FC<
  Pick<Folder, "name"> & { onClick: () => void }
> = ({ name, onClick }) => {
  return (
    <Cluster
      onClick={onClick}
      style={{
        cursor: "pointer",
        backgroundColor: "white",
        padding: 16,
        borderBottom: `1px solid ${colorPaletteValues["color-gray-500"]}`,
      }}
    >
      <IconFolderLarge />
      <Text>{name}</Text>
    </Cluster>
  );
};
