import React, { FunctionComponent } from "react";
import { Alert } from "@templafy/ui/components/alert/Alert";
import { Cluster } from "@templafy/ui/components/cluster/Cluster";
import { IconInfo } from "@templafy/ui/icons/IconInfo";
import { Text } from "@templafy/ui/components/text/Text";
import { Button } from "@templafy/ui/components/button/Button";

import { openPopup } from "helpers";
import { GeneratedFile, GenerateDocumentFormValues } from "types";

type UnattendedFailedProps = {
  url?: string;
  data: GenerateDocumentFormValues["data"];
  setGeneratedDocument: (generateDocument: Partial<GeneratedFile>) => void;
};

export const UnattendedFailed: FunctionComponent<UnattendedFailedProps> = ({
  url,
  data,
  setGeneratedDocument,
}) => {
  if (!url) {
    return null;
  }
  const tryAttendedFlow = () =>
    openPopup({ popupUrl: url, data, setGeneratedDocument });

  return (
    <Alert gap={400} status="warning" style={{ marginBottom: 40 }}>
      <Cluster gap={200}>
        <IconInfo />
        <Text>
          Unattended document creation failed. Try to create attended by
          clicking the button
        </Text>
      </Cluster>
      <Button variant={"ghost"} onClick={tryAttendedFlow} size={"small"}>
        Try attended
      </Button>
    </Alert>
  );
};
