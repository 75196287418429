import React, { FunctionComponent } from "react";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/monokai";
import { useFormikContext } from "formik";
import { defaultProps, Editor } from "./jsonEditor/JsonEditorReact";
import { GenerateDocumentFormValues } from "../types";

export const JsonEditor: FunctionComponent = () => {
  const { values, setFieldValue } =
    useFormikContext<GenerateDocumentFormValues>();

  return (
    <Editor
      {...defaultProps}
      htmlElementProps={{ style: { width: "100%" } }}
      ace={ace}
      theme={"ace/theme/monokai"}
      value={values.data}
      onChange={(value: Record<string, any>) => setFieldValue("data", value)}
    />
  );
};
