import React, { FunctionComponent } from "react";
import { Cluster } from "@templafy/ui/components/cluster/Cluster";
import { IconTemplafyLargeColor } from "@templafy/ui/icons/IconTemplafyLargeColor";
import { Link } from "@templafy/ui/components/link/Link";
import { Text } from "@templafy/ui/components/text/Text";
import { Center } from "@templafy/ui/components/center/Center";
import { Stack } from "@templafy/ui/components/stack/Stack";

const NavHeader: FunctionComponent = () => {
  return (
    <Cluster justifyContent="space-between" style={{ padding: "16px 24px" }}>
      <Cluster>
        <IconTemplafyLargeColor
          style={{
            height: "60px",
            width: "60px",
          }}
        />
        <Text as="h3">Templafy Automated Generate Document Demo</Text>
      </Cluster>
      <Cluster
        as="ul"
        style={{
          listStyleType: "none",
          margin: 0,
          padding: 0,
        }}
      >
        <li>
          <Link external href="#1">
            First
          </Link>
        </li>
        <li>
          <Link external href="#2">
            Second
          </Link>
        </li>
        <li>
          <Link external href="#3">
            Third
          </Link>
        </li>
        <li>
          <Link external href="#4">
            Fourth
          </Link>
        </li>
        <li>
          <Link external href="#5">
            Fifth
          </Link>
        </li>
      </Cluster>
    </Cluster>
  );
};
export const Layout: FunctionComponent<{ children: React.ReactElement }> = ({
  children,
}) => {
  return (
    <Stack gap={400}>
      <NavHeader />
      <Stack gap={600} style={{ height: "calc(100vh - 92px)" }}>
        <Center maxWidth={1600} centerText>
          <Stack gap={300}>
            <Text as={"h5"}>
              Environment to witness the power of Document Automation with
              Templafy.
            </Text>
          </Stack>
        </Center>
        {children}
      </Stack>
    </Stack>
  );
};
