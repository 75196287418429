import React, { FunctionComponent } from "react";
import { Formik } from "formik";

import { Background } from "@templafy/ui/components/background/Background";
import { ColorPalette } from "@templafy/ui/designTokens/generated/DesignTokens";
import { Cluster } from "@templafy/ui/components/cluster/Cluster";
import { useGenerateDocumentForm } from "hooks/useGenerateDocumentForm";

import { GenerateDocument } from "./GenerateDocument";
import { JsonEditor } from "./JsonEditor";

export const Home: FunctionComponent = () => {
  const { initialValues, validate, onSubmit } = useGenerateDocumentForm();
  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      validateOnChange={false}
    >
      <Cluster flexGrow={1} gap={0} alignItems={"stretch"}>
        <Background
          color={ColorPalette.Neutral800}
          flexGrow={1}
          flexBasis={"50%"}
          style={{ padding: 50 }}
        >
          <JsonEditor />
        </Background>
        <Background
          color={ColorPalette.Neutral100}
          flexGrow={1}
          flexBasis={"50%"}
          style={{ padding: 50 }}
        >
          <GenerateDocument />
        </Background>
      </Cluster>
    </Formik>
  );
};
