import { NotificationService } from "@templafy/notifications";
import { GenerateDocumentFormValues, LibraryType } from "../types";

const BASE_URL = `api.templafy.com/unstable`;
const PROTOCOL = `https://`;

export const endpoints = {
  Libraries: "libraries",
};

const COMMON_HEADERS_FOR_POST = {
  accept: "application/json",
  "content-type": "application/json",
};

export class ApiError extends Error {
  public code: number;
  public data: Record<string, any>;

  constructor(message: string, code: number, data: Record<string, any> = null) {
    super(message);
    this.code = code;
    this.data = data;
  }
}

export const getBaseUrl = (tenantName: string) =>
  `${PROTOCOL}${tenantName}.${BASE_URL}`;

const getHeaders = (apiKey: string) => ({
  Authorization: `Bearer ${apiKey}`,
});

type ApiRequestOptions = {};
const request = async (url: string, options: ApiRequestOptions) => {
  try {
    const response = await fetch(url, options);

    if (response.status >= 400 && response.status < 500) {
      throw new ApiError(`Authorization Required`, response.status);
    }

    if (response.status === 500) {
      throw new ApiError("Unattended failed", response.status);
    }

    return await response.json();
  } catch (e) {
    if (!(e instanceof ApiError)) {
      NotificationService.error({
        title: "API request failed",
        description: `Something went wrong while fetching data.`,
      });
    }
    throw e;
  }
};

export type GetLibrariesApiParams = Pick<
  GenerateDocumentFormValues,
  "tenantName" | "apiKey"
>;

export const getLibraries = async ({
  tenantName,
  apiKey,
}: GetLibrariesApiParams) => {
  return request(`${getBaseUrl(tenantName)}/${endpoints.Libraries}`, {
    method: "GET",
    headers: getHeaders(apiKey),
  });
};

export type GetAssetApiParams = Pick<
  GenerateDocumentFormValues,
  "tenantName" | "apiKey" | "assetId"
> & {
  spaceId: string;
  libraryType: LibraryType;
};

export const getAssetById = async ({
  tenantName,
  apiKey,
  spaceId,
  libraryType,
  assetId,
}: GetAssetApiParams) => {
  return request(
    `${getBaseUrl(tenantName)}/${
      endpoints.Libraries
    }/${spaceId}/${libraryType}/assets/${assetId}`,
    {
      method: "GET",
      headers: getHeaders(apiKey),
    }
  );
};

export type GenerateDocumentApiParams = GetAssetApiParams &
  Pick<GenerateDocumentFormValues, "email" | "data">;

export const generateDocument = async ({
  tenantName,
  apiKey,
  spaceId,
  libraryType,
  assetId,
  email,
  data,
}: GenerateDocumentApiParams) => {
  return request(
    `${getBaseUrl(tenantName)}/${
      endpoints.Libraries
    }/${spaceId}/${libraryType}/assets/${assetId}/generate`,
    {
      method: "POST",
      headers: {
        ...getHeaders(apiKey),
        ...COMMON_HEADERS_FOR_POST,
      },
      body: JSON.stringify({
        email,
        data,
      }),
    }
  );
};

export const getLibraryFolder = async ({
  tenantName,
  apiKey,
  libraryType,
  spaceId,
  folderId,
}: GetLibraryFoldersParams) => {
  return request(
    `${getBaseUrl(tenantName)}/${
      endpoints.Libraries
    }/${spaceId}/${libraryType}/folders/${folderId}`,
    {
      method: "GET",
      headers: getHeaders(apiKey),
    }
  );
};

export type GetLibraryFoldersParams = Pick<
  GenerateDocumentFormValues,
  "tenantName" | "apiKey"
> & {
  spaceId: string;
  libraryType: LibraryType;
  folderId: string;
};
export const getLibraryFolders = async ({
  tenantName,
  apiKey,
  libraryType,
  spaceId,
  folderId,
}: GetLibraryFoldersParams) => {
  return request(
    `${getBaseUrl(tenantName)}/${
      endpoints.Libraries
    }/${spaceId}/${libraryType}/folders/${folderId}/folders`,
    {
      method: "GET",
      headers: getHeaders(apiKey),
    }
  );
};

export type GetLibraryAssetsParams = Pick<
  GenerateDocumentFormValues,
  "tenantName" | "apiKey"
> & {
  spaceId: string;
  libraryType: LibraryType;
  folderId: string;
};
export const getLibraryAssets = async ({
  tenantName,
  apiKey,
  libraryType,
  spaceId,
  folderId,
}: GetLibraryFoldersParams) => {
  return request(
    `${getBaseUrl(tenantName)}/${
      endpoints.Libraries
    }/${spaceId}/${libraryType}/folders/${folderId}/assets`,
    {
      method: "GET",
      headers: getHeaders(apiKey),
    }
  );
};
