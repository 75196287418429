import React, { FC } from "react";
import { Card, CardSize } from "@templafy/ui/components/card/Card";
import { AnimatedDocumentIllustration } from "@templafy/ui/illustrations/AnimatedDocumentIllustration";
import { AnimatedPresentationIllustration } from "@templafy/ui/illustrations/AnimatedPresentationIllustration";
import { AnimatedSpreadsheetIllustration } from "@templafy/ui/illustrations/AnimatedSpreadsheetIllustration";

import { Library, LibraryType } from "types";

export const SpaceRow: FC<Library & { onClick: () => void }> = ({
  name,
  spaceId,
  libraryType,
  onClick,
}) => {
  const illustration = getIllustration(libraryType);

  return (
    <Card
      onClick={onClick}
      title={name}
      subtitle={spaceId}
      illustration={illustration}
      width={"100%"}
      size={CardSize.Small}
    />
  );
};

const getIllustration = (libraryType: LibraryType) => {
  switch (libraryType) {
    case LibraryType.documents: {
      return AnimatedDocumentIllustration;
    }
    case LibraryType.presentations: {
      return AnimatedPresentationIllustration;
    }
    case LibraryType.spreadsheets: {
      return AnimatedSpreadsheetIllustration;
    }
  }
};
