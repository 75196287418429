import { GeneratedFile, GenerateDocumentFormValues } from "./types";

const key = "templafy_api_tool_base_state";

export type SaveBaseStateToLocalStorageParams = Pick<
  GenerateDocumentFormValues,
  "tenantName" | "apiKey" | "email"
>;
export const saveBaseStateToLocalStorage = ({
  tenantName,
  apiKey,
  email,
}: SaveBaseStateToLocalStorageParams) => {
  try {
    localStorage.setItem(key, JSON.stringify({ tenantName, apiKey, email }));
  } catch (e) {
    console.error(e);
  }
};

export const getBaseStateFromLocalStorage =
  (): SaveBaseStateToLocalStorageParams | null => {
    const state = localStorage.getItem(key);
    return JSON.parse(state);
  };

export const openDocumentInOffice = (fileUrl: string, fileExtension: string) => {
    let schemaName = "ms-word";
    if (fileExtension == 'xlsx') {
        schemaName = "ms-excel";
    } else if (fileExtension == 'pptx') {
        schemaName = "ms-powerpoint";
    } 
    window.open(schemaName + ":ofe|u|" + fileUrl);
};

export type OpenPopupParams = {
  popupUrl: string;
  data: GenerateDocumentFormValues["data"];
  setGeneratedDocument: (generatedDocument: Partial<GeneratedFile>) => void;
};

export const openPopup = ({
  popupUrl,
  data: HostSystemData,
  setGeneratedDocument,
}: OpenPopupParams) => {
  const features =
    "menubar=no,location=no,resizable=no,scrollbars=no,status=no,titlebar=no,toolbar=no,width=1500,height=1000";

  let openedPopup: Window | undefined;

  const messageEventHandler = ({ data }: { data: any }) => {
    if (data?.type === "ready") {
      const url = new URL(popupUrl);
      openedPopup?.postMessage({ type: "content", content: HostSystemData }, url.origin);
    } else if (data?.type === "document") {
      setGeneratedDocument({ downloadUrl: data.documentUrl });

      openedPopup?.close();

      window.removeEventListener("message", messageEventHandler);
    }
  };

  openedPopup = window.open(popupUrl, "_blank", features);

  if (openedPopup) {
    window.addEventListener("message", messageEventHandler);
  }
};
