export enum LibraryType {
  documents = "documents",
  presentations = "presentations",
  spreadsheets = "spreadsheets",
  slides = "slides",
  slideElements = "slide-elements",
  textElements = "text-elements",
  images = "images",
  pdfs = "pdfs",
  links = "links",
  emailElements = "email-elements",
}

export type Library = {
  id: string;
  name: string;
  spaceId: string;
  libraryType: LibraryType;
  rootFolderId: string;
};

export type Asset = {
  id: string;
  folderId: string;
  name: string;
  description: string;
};

export type GenerateDocumentFormValues = {
  tenantName: string;
  apiKey: string;
  email: string;
  libraries: Array<Library>;
  library: Library | null;
  assetId: string;
  asset: Asset | null;
  data: Record<string, any>;
  apiUrl: string;
  popupUrl: string;
};

export type GeneratedFile = {
  downloadUrl: string;
  fileSize: number;
  checksum: string;
  mimeType: string;
  fileExtension: string;
};

export type Folder = {
  id: string;
  libraryId: string;
  name: string;
  parentId: string;
  navigationPath: string;
  modifiedAt: string;
};